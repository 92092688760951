$border-color: #eee;

.schedule {
    font-family: 'M PLUS Rounded 1c', sans-serif !important;
    font-weight: 400;
    height: 100%;
    background-color: white;
    padding: 30px;

    &__container {
        margin-top: 15px;
        max-width: 1200px;
        text-align: center;
        // padding: 10px;
    }

    &__weekdays {
        font-size: 13px;
        border-bottom: solid 1px $border-color;
        &>th {
            padding: 14px 0;
        }
    }

    &__cities {
        font-size: 10px;
        // border-bottom: solid 1px $border-color;
        &>th {
            padding: 10px;
            width: 60px;
            vertical-align: bottom;
        }
    }
    table {
        border-spacing: 0;
        border: solid 1px $border-color;
        display: block;
        border-radius: 5px;
        .nechet {
            border-right: solid 1px $border-color;
        }
    }
    td {
        font-size: 12px;
        padding: 4px;
    }
}

.schedule-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;

    &__logo {
        max-width: 100%;
        max-height: 45px;
        padding-left: 10px;
    }

    &__left {
        display: flex;
        width: 25%;
    }
    &__right {
        width: 25%;
        text-align: right;
    }

    &__title {
        text-align: center;
        flex: 1;
        h1 {
            font-size: 25px;
            margin: 0;
        }
        h2 {
            font-size: 20px;
            margin: 0;
            font-weight: 400;
        }
    }

    &__link {
        color: #4BB9DF;
        text-align: right;
        font-weight: 500;
        font-size: 24px;
        padding-right: 10px;
    }
}

.gray {
    background-color: #4bb9df1f;
}