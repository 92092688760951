.app {
  min-height: 100vh;
}

.content {
  padding: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  float: left;
  margin-right: 60px;
}
.logo img {
  max-height: 30px;
}

@media (max-width: 600px) {
  .content {
    padding: 12px 12px;
  }
}
.urls {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2392156863);
  width: 100%;
  max-width: 1000px;
}
.urls__buttons button {
  margin-right: 20px;
  margin-bottom: 10px;
}

.ant-form-item {
  margin-bottom: 14px;
}
.schedule {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  font-weight: 400;
  height: 100%;
  background-color: white;
  padding: 30px;
}
.schedule__container {
  margin-top: 15px;
  max-width: 1200px;
  text-align: center;
}
.schedule__weekdays {
  font-size: 13px;
  border-bottom: solid 1px #eee;
}
.schedule__weekdays > th {
  padding: 14px 0;
}
.schedule__cities {
  font-size: 10px;
}
.schedule__cities > th {
  padding: 10px;
  width: 60px;
  vertical-align: bottom;
}
.schedule table {
  border-spacing: 0;
  border: solid 1px #eee;
  display: block;
  border-radius: 5px;
}
.schedule table .nechet {
  border-right: solid 1px #eee;
}
.schedule td {
  font-size: 12px;
  padding: 4px;
}

.schedule-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 100px;
}
.schedule-header__logo {
  max-width: 100%;
  max-height: 45px;
  padding-left: 10px;
}
.schedule-header__left {
  display: -webkit-flex;
  display: flex;
  width: 25%;
}
.schedule-header__right {
  width: 25%;
  text-align: right;
}
.schedule-header__title {
  text-align: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.schedule-header__title h1 {
  font-size: 25px;
  margin: 0;
}
.schedule-header__title h2 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
}
.schedule-header__link {
  color: #4BB9DF;
  text-align: right;
  font-weight: 500;
  font-size: 24px;
  padding-right: 10px;
}

.gray {
  background-color: rgba(75, 185, 223, 0.1215686275);
}
@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 500;
  src: local('M PLUS Rounded 1c'), url(/static/media/MPLUSRounded1c-Medium.1bf99563.woff) format('woff');
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 400;
  src: local('M PLUS Rounded 1c'), url(/static/media/MPLUSRounded1c-Regular.67b19215.woff) format('woff');
}

/* MPLUSRounded1c-Regular.woff */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

