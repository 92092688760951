.urls {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 5px #0000003d;
    width: 100%;
    max-width: 1000px;

    &__buttons {
        button {
            margin-right: 20px;
            margin-bottom: 10px;
        }
    }
}

.ant-form-item {
    margin-bottom: 14px;
}