@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 500;
  src: local('M PLUS Rounded 1c'), url(./fonts/MPLUSRounded1c-Medium.woff) format('woff');
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  font-weight: 400;
  src: local('M PLUS Rounded 1c'), url(./fonts/MPLUSRounded1c-Regular.woff) format('woff');
}

/* MPLUSRounded1c-Regular.woff */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
