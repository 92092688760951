.app {
  min-height: 100vh;
}

.content {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  float: left;
  margin-right: 60px;
  img {
    max-height: 30px;
  }
}

@media (max-width: 600px) {
  .content {
    padding: 12px 12px;
  }
}